@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.addToBasketWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;

  @include screen-md {
    flex-direction: row;
  }

  button {
    flex-grow: 1;
  }
}

.articleCounter {
  max-width: 7.5rem;
  margin-right: 1.875rem;
  margin-bottom: 1.875rem;

  button {
    :global(.icon::before) {
      color: var(--gb-brand-primary);
    }
  }

  @include screen-md {
    margin-bottom: 0;
  }
}

.addToBasketButton {
  :global(.icon::before) {
    font-size: 1.125rem;
  }
}
