@import 'styles/base/functions';

/* override global css */
.title {
  .headlineH1.withSubtitle & {
    margin-bottom: 0;
  }

  :global(.gdds) & {
    margin-left: auto;
    margin-right: auto;
  }
}

.subtitle {
  display: block;

  &.weightBold {
    font-weight: 700;
  }
  &.weightMedium {
    font-weight: 500;
  }
  &.weightRegular {
    font-weight: 400;
  }
  &.weightLight {
    font-weight: 300;
  }
}

.uppercase.uppercase.uppercase.uppercase {
  text-transform: uppercase;
}

.headlineH1 > h2 {
  letter-spacing: calc-letter-spacing(5);
}
