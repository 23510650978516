@import '../../../styles/base/variables';

.technicalDrawings {
  margin-bottom: 1.875rem;

  &Headline {
    margin-bottom: 1.875rem;
  }

  &Imgs {
    border: $border-grey;
    margin-bottom: 1.875rem;
  }
}
