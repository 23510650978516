@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.productTiles {
  margin-top: 1.5rem;

  > div {
    overflow: visible;
    display: block;
  }

  > div > a:global(.gdds-element) {
    display: block !important;
    height: 100% !important;
    width: 100% !important;
    color: $gb_black !important;
    border: 1px solid $gb_grey_200 !important;
    padding: 0 !important;
    box-sizing: border-box;
    transform: none !important;

    &::before {
      display: none;
    }

    &:focus {
      outline: 1px solid var(--gb-brand-primary-light);
      border: 1px solid var(--gb-brand-primary-light);
    }

    &:active {
      border: 1px solid $gb_black;
      background: none;
    }

    &:hover {
      text-decoration: none;
      color: $gb_black;

      & > span {
        border-bottom: 1px solid transparent;
      }
    }

    position: relative;
    // border top bottom otherwise it is not visible in carousel
    background-color: $gb_white;
    overflow: hidden;

    &:hover,
    &:active {
      .content {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .content {
      padding: 1.5rem 1.5rem 3.75rem;
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      height: 100%;
    }

    .name {
      font-weight: $font_weight_bold;
    }

    .amount {
      color: $gb_grey_650;
      position: absolute;
      bottom: 1.5rem;
      left: 1.5rem;
      font-size: 0.875rem;
    }

    .imageWrapper {
      display: flex;
      justify-content: center;
      padding-top: 1rem;
      padding-bottom: 1rem;

      img {
        width: 9.5rem;

        @include screen-lg {
          width: 10rem;
        }
      }
    }

    .newLabel {
      position: absolute;
      top: 1.5rem;
      right: 0;
      padding: 0.25rem 0.5rem;
      background: var(--product-tiles-new-flag);
      color: $gb_white;
      text-transform: uppercase;
    }
  }
}

.wrapper {
  margin-top: 0.5rem;
}

.productLoadMore {
  margin-top: 2rem;
}
