@import '../../styles/base/variables';
@import '../../styles/vendor/icons';
@import '../../styles/base/mixins';

:global(.with-mega-menu) {
  .breadcrumb {
    @include below-screen-sm {
      display: none;
    }

    @include screen-sm {
      display: block;
    }
  }
}

.breadcrumb {
  display: none;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;

  &.gdds {
    margin: 2rem auto;
    padding: 0 1.5rem;

    &:global(.nord) {
      margin: 1rem auto;
    }
  }

  &.nord {
    display: none;

    @include screen-sm {
      display: block;
    }
  }

  @media (min-width: 40em) {
    display: block;
  }

  a {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &::before {
      content: none;
    }
  }

  ol {
    align-items: center;
    display: flex;
    margin: 0;
  }

  &Item {
    align-items: center;
    display: inline-flex;
    list-style: none;
    padding: 0;

    &::before {
      content: none;
    }

    &:not(:first-child)::before {
      @include web20-icon('breadcrumb-arrow');
      color: $gb_grey_400;
      font-size: 0.875rem;
      padding: 0 0.625rem 0 0.625rem;
      position: relative;
      top: 1px;
    }

    &:visited {
      color: $gb_grey_650;
    }

    a {
      &::before {
        display: none;
      }
    }

    &Home a {
      color: $gb_grey_650;
      cursor: pointer;

      &::before {
        content: none;
      }

      .breadcrumbItemHomeIcon {
        bottom: 2px;
        position: relative;

        &::before {
          @include web20-icon('breadcrumb-home');
          color: $gb_grey_650;
          font-size: 1rem;
        }
      }
    }

    &Disabled,
    &Disabled a {
      color: $gb_grey_400;
      font-size: 0.9375rem;
      line-height: 1.6;
    }
  }
}
