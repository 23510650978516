@import 'styles/base/variables';
@import 'styles/base/mixins';

.gddsForm {
  margin: $spacing-gdds-section-mobile-large;
  --gap: 1.5rem;
  --half: calc(calc(100% - var(--gap)) / 2);

  @include screen-sm {
    margin: $spacing-gdds-section-small;
    margin-left: auto;
    margin-right: auto;
  }

  form {
    border-bottom: 0;

    .rowElement {
      margin: 0;

      ul > li {
        padding-bottom: 0.625rem;
      }

      > .gddsField {
        padding: 0;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    label {
      p,
      a,
      a > span {
        font-size: 0.875rem;
      }
    }

    // override global styles again
    input[type='text'],
    input[type='email'],
    input[type='date'],
    input[type='number'],
    input[type='password'],
    textarea,
    select {
      cursor: initial;
      padding: 0 0.5rem;
      border: 1px solid $gb_gdds_cl10b;
      box-shadow: none;

      &:active,
      &:focus {
        border: 1px solid #000000;
      }

      &:hover {
        border: 1px solid $gb_grey_700;
      }
    }

    textarea {
      padding: 0.375rem 0.5rem;
    }

    .hasError {
      input[type='text'],
      input[type='email'],
      input[type='date'],
      input[type='number'],
      input[type='password'],
      textarea,
      select {
        border: 1px solid $gb_gdds_error;

        &:active {
          border: 1px solid $gb_black;
        }

        &:hover {
          border: 1px solid $gb_grey_700;
        }
      }
    }

    :global {
      .cell {
        margin-bottom: 1rem;
      }

      // style upload button like the GDDS button
      label.form-item__file-upload {
        min-width: 6.25rem;
        background-color: $gb_gdds_cl17;
        border: 1px solid $gb_gdds_cl17;
        font-size: 1rem;
        line-height: 1.5rem;
        min-height: 3rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: $gb_fontFamilyBase;
        font-weight: 500;
      }
    }
  }

  .formFieldset,
  .formFieldsGroup {
    width: 100%;
    column-gap: var(--gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include screen-sm {
      margin: 0;
    }

    :global(.full) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
    }

    :global(.half) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;

      @include screen-md {
        flex-basis: var(--half);
      }
    }

    & .gddsField {
      padding: 0;
      margin-bottom: 1rem;
    }
  }

  .formFieldset {
    position: relative;
    padding: 4.5rem 0.625rem 1.5rem 0.625rem;
    margin-bottom: 1.5rem;
    background-color: $gb_white;

    @include screen-sm {
      padding: 5rem 0.938rem 2rem 0.938rem;
    }

    &.light-grey {
      background-color: $gb_gdds_cl03;
    }

    &.white {
      background-color: $gb_white;
    }

    &.grey {
      background-color: $color-black-06;
    }

    &.porcelain {
      background-color: $porcelain;
    }

    legend {
      all: unset;
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-weight: 700;
      color: $gb_black;
      position: absolute;
      top: 2rem;
    }
  }

  .formFieldsGroup {
    padding-bottom: 1.5rem;

    @include screen-sm {
      padding-bottom: 2rem;
    }
  }

  .spacer {
    display: none;

    @include screen-sm {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: var(--half);
    }
  }

  h2:not(:global(.h3)) {
    margin-bottom: 0.75rem;
  }

  .copyText {
    margin-bottom: 2.5rem;
    @include bullet-points-spacing();

    > p {
      margin-bottom: 0rem;
    }
  }

  span:global(.h4) {
    display: block;
  }
}

.formBorder {
  border-bottom: 1px solid $gb_black;
  margin-bottom: 3.75rem;
  padding-bottom: 5.625rem;
}

.disableBorder {
  border: none;
  padding-bottom: 0;
}

.formContainer {
  --gap: 1.5rem;
  --half: calc(calc(100% - var(--gap)) / 2);

  .formFieldset,
  .formFieldsGroup {
    width: 100%;
    column-gap: var(--gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    :global(.full) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
    }

    :global(.half) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;

      @include screen-md {
        flex-basis: var(--half);
      }
    }

    & .gddsField {
      padding: 0;
      margin-bottom: 1rem;
    }

    span:global(.h4) {
      display: block;
    }
  }

  .formFieldset {
    position: relative;
    padding: 4.5rem 0.625rem 1.5rem 0.625rem;
    margin-bottom: 1.5rem;
    background-color: $gb_white;

    @include screen-sm {
      padding: 5rem 0.938rem 2rem 0.938rem;
    }

    &.light-grey {
      background-color: $gb_gdds_cl03;
    }

    &.white {
      background-color: $gb_white;
    }

    &.grey {
      background-color: $color-black-06;
    }

    &.porcelain {
      background-color: $porcelain;

      :global(.xy) & {
        background-color: var(--cl05);
      }
    }

    legend {
      all: unset;
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-weight: 700;
      color: $gb_black;
      position: absolute;
      top: 2rem;
    }
  }

  .formFieldsGroup {
    padding-bottom: 1.5rem;

    @include screen-sm {
      padding-bottom: 2rem;
    }
  }

  .spacer {
    display: none;

    @include screen-sm {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: var(--half);
    }
  }

  h2 {
    margin-bottom: 0.75rem;
  }

  .copyText {
    margin-bottom: 2.5rem;
    @include bullet-points-spacing();

    > p {
      margin-bottom: 0rem;
    }
  }
}

.gddsToggleButtons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  @include screen-sm {
    gap: 1.5rem;
  }

  :global(.image--aspect-ratio) {
    width: 100%;
    margin-bottom: 0.5rem;

    @include screen-sm {
      width: 212px;
    }
  }

  > div {
    flex-wrap: wrap;
  }

  label {
    min-height: 2.5rem;

    p,
    a {
      font-size: 0.75rem !important;
      line-height: 1rem !important;
    }
  }

  input[type='radio']:checked + label:active > span {
    display: block;
    // needs important because of platform overrides
    background: $gb_gdds_cl12 !important;
  }

  div[type='checkbox'] + span {
    display: block;
    width: 100%;
  }
}

.gddsTimepickerLabel {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #666666;
  padding-bottom: 0.25rem;
  display: block;
}

.gddsTimepicker {
  label {
    color: #666666;
  }
}

.gddsDatepicker {
  label {
    color: #666666;
  }

  width: 100%;
  > div {
    width: 100%;

    > div:first-child {
      width: 100%;

      > div {
        width: 100%;
      }

      input {
        width: 100%;
      }
    }

    > div:not(:first-child):last-child {
      max-width: 320px;
    }
  }
}

.gddsToggleLabel {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #666666;
  padding-bottom: 0.25rem;
  display: block;
}

.gddsToggleButtonsImage {
  > div {
    width: 100%;
    max-width: calc(50% - 1rem);
  }

  @include screen-sm {
    > div {
      width: auto;
      max-width: calc(50% - 1.5rem);
    }
  }
}
