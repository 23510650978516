@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';

.basketBox {
  background-color: $gb_grey_050;
  height: 10.125rem;
  padding-bottom: 0.938rem;
  margin-top: 0.938rem;

  @include screen-md {
    margin-top: 0;
  }

  .basketHeader {
    align-items: center;
    background-color: $gb_black;
    color: $gb_white;
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    height: 3.563rem;
    margin-bottom: 0.625rem;
    padding: 0 2rem;

    @include screen-max-md {
      width: 100%;
    }
  }

  .basketButtons {
    align-items: center;
    display: flex;

    &:first-child {
      padding-top: 1.125rem;
    }

    .cartCounter {
      align-items: center;
      background-color: $gb_black;
      border-radius: 50%;
      color: $gb_white;
      display: flex;
      height: 1.625rem;
      justify-content: center;
      width: 1.625rem;
    }

    .basketIcon {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding-right: 0.625rem;
    }

    .basketDescription {
      flex: 6;

      button {
        display: flex;
        font-size: 1rem;
        justify-content: flex-start;
        text-align: left;
      }
    }

    .counterContainer {
      padding-left: 0.625rem;
      flex: 3;

      @include screen-max-md {
        flex: 2;
      }
    }
  }
}
