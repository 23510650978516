@import '../../styles/base/variables';
@import '../../styles/base/mixins';

a.isDownload.isDownload.isDownload {
  &:hover {
    svg {
      fill: $gb-black;
    }

    span {
      color: $gb-black;
    }
  }

  &:active {
    svg {
      fill: $gb-white;
    }

    span {
      color: $gb-white;
    }
  }

  svg {
    width: 1.25rem;
    fill: $gb-black;
  }
}

.isGdds {
  table {
    th,
    td {
      font-size: 1rem;
    }
  }
}

.displayLineBreak {
  &#{&}#{&} {
    white-space: pre-line;
  }
}

.headlineWrapper {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;

  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
