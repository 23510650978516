@import 'styles/base/variables';
@import 'styles/vendor/icons';
@import 'styles/base/mixins';

.centeredCol {
  justify-content: center;
  align-items: center;

  :global(.c-search-navigation) {
    margin-top: 2.5rem;
    margin-bottom: 2rem;

    > div:first-child {
      margin-bottom: 2.5rem;
    }

    @include screen-sm {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 2.5rem;
      gap: 1rem;
      margin-left: auto;
      margin-right: auto;

      > div {
        flex: 1;
        max-width: unset;
      }

      > div:first-child {
        margin-bottom: 0;
      }
    }
  }

  :global(.without-filter) {
    @include screen-sm {
      max-width: 19rem;
    }
  }

  form {
    border-bottom: none;

    > button + div {
      z-index: 80;
    }
  }

  :global(.c-search-bar__line) {
    border-bottom: 1px solid $gb_gdds_cl10;

    input {
      font-size: 1rem;
      color: $gb_grey_600;

      &::placeholder {
        color: $gb_grey_600;
      }
    }
  }
  :global(.c-search-bar__btn) {
    i::before {
      fill: $gb_grey_600;
    }
  }
}

.textbox {
  h1, h2 {
    margin-bottom: 1rem;
  }

  p,
  &#{&}#{&} a {
    font-size: inherit;
    line-height: inherit;
  }
}

.headline {
  :global(.nord) & {
    h2, h2 > span {
      font-weight: $font-weight-light;
      text-transform: none;
    }
  }

  div > span {
    font-size: 1.125rem;
    font-weight: $font-weight-regular;
  }
}

.cart {
  padding: 1.5rem;
  background-color: var(--gb-brand-primary-light);
  color: $gb_white;
  width: 100%;
  margin-top: 4rem;

  @include screen-md {
    margin-left: auto;
    max-width: 18rem;
    margin-top: 0;
  }

  .shoppingBasket {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  a {
    cursor: pointer;
    width: 100%;

    @include screen-sm {
      width: auto;
    }

    @include screen-md {
      width: 100%;
    }

    > span {
      margin-right: 0.5rem;
    }

    &::before {
      display: none;
    }

    &:first-child {
      margin-bottom: -0.5rem;
    }
  }
}

.tilesContainer {
  margin-top: 1.5rem;
}

.filterRow {
  margin-bottom: 1rem;

  @include screen-md {
    margin-bottom: 1.5rem;
  }

  > div > div > div {
    margin-bottom: 1rem;

    @include screen-md {
      margin-bottom: 0;
    }
  }

  > div {
    row-gap: 1rem;
  }

  :global(.xy) & {
    p {
      font-size: 1rem;
    }
  }
}

.filterForm {
  border-bottom: none;
  display: block;
}

.tileFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .results {
    margin: 0;
  }

  .sort {
    font-size: 1rem;
    width: 8rem;

    ul > li:last-of-type {
      padding-bottom: 0.625rem;
    }
  }
}

.cartColumn {
  margin: 0 auto;
}

.loadMore {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;

  > button {
    min-width: 100%;

    @include screen-sm {
      min-width: 16rem;
    }

    > span:nth-child(2) {
      all: unset;
    }
  }
}

.loadMoreHidden {
  visibility: hidden;
}

.noResultsHeadline h2 {
  margin-bottom: 1.5rem
}

.suggestionsHeadline h4 {
  text-transform: none !important;
}
