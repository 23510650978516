@import 'styles/base/variables';
@import 'styles/base/mixins';

.orders,
.downloads {
  margin-bottom: 2rem;

  table {
    margin: 0;
  }

  > p {
    margin-bottom: 0.75rem;
    padding-left: 1rem;
  }

  tr {
    border-left: none;

    th,
    td {
      border-left: none;
    }

    td {
      vertical-align: middle;
    }

    td:nth-of-type(2) {
      text-align: left !important;
    }

    input {
      height: 2.5rem;
      width: 4rem;
      text-align: center;
      padding: 0;
    }

    button {
      font-weight: 500 !important;
      padding-right: 1rem !important;
    }

    span {
      align-items: center;
      width: initial;
    }
  }

  button {
    color: var(--gb-brand-primary-dark);

    svg {
      fill: var(--gb-brand-primary-dark);
    }
  }
}

.orders {
  padding: 0 0.5rem;
}

.downloads {
  flex-direction: column;
}

.order {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    > input {
      width: 4rem;
      text-align: center;
    }

    > button {
      padding: 0 1rem 0 0;
      font-weight: 500;

      > span {
        width: initial;
        align-items: center;
      }
    }
  }

  &__designation {
    &#{&} > p {
      margin-bottom: 0.75rem;
      max-width: initial;
    }
  }

  button {
    font-size: 1rem;
  }
}

.download {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    > p {
      margin: 0;
    }

    > input {
      width: 4rem;
      text-align: center;
    }

    > button {
      font-weight: 500;
      padding: 0 1rem 0 0;

      > span {
        width: initial;
        align-items: center;
      }
    }
  }

  &__designation {
    > p {
      margin-bottom: 0.75rem;
      max-width: initial;
    }
  }

  button {
    font-size: 1rem;
  }
}

.download_button {
  display: flex;
  justify-content: center;

  button {
    width: 100%;

    > span {
      font-size: 0.875rem;
      width: unset;
      align-items: center;
    }

    @include screen-md {
      width: initial;
    }
  }

  @include screen-md {
    justify-content: flex-end;
  }
}

.form__title {
  font-size: 1.125rem !important;
  line-height: 1.5rem !important;

  &#{&} span {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
