@import '../../styles/base/variables';
@import '../../styles/vendor/icons';
@import '../../styles/base/functions';
@import '../../styles/base/mixins';

.videoPlayButton {
  @include playIcon(50, 75, true, 5);
  height: 4.6875rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4.6875rem;
  z-index: 10;

  &::after {
    top: 50%;
  }
}
