@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';

@include screen-max-md {
  .dlcContainer {
    padding-top: 1.875rem;
  }

  .headlineSubline {
    padding: 0 1rem;
  }
}

.searchBar {
  margin-top: 5.625rem;
  width: 100%;
  z-index: 2;

  @include screen-max-md {
    margin-top: 2.5rem;
  }

  @include screen-lg {
    padding: 0 0.5rem;
  }
}

.filterChipsArea {
  margin-top: 2rem;
  padding-left: 0.75rem;

  .filterChipsWrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

.filterChip {
  padding-right: 0.938rem;
  padding-bottom: 0.938rem;

  &:first-child {
    margin-right: 1.5rem;
    padding-right: 0;
  }
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

.filterArea {
  margin-top: 5rem;
  display: flex;
  align-items: flex-end;

  @include screen-max-md {
    margin-top: 2.5rem;
  }

  .sortDropdown {
    padding: 0 0.75rem;
    margin-left: auto;

    @include screen-max-md {
      margin-top: 1rem;
    }
  }

  .filterChipWrapperMobile {
    display: flex;
    padding: 1rem 0 0 0.5rem;
    flex-wrap: wrap;
  }

  @include only-screen-md {
    .resetButton {
      margin-top: 1rem;
    }
  }
}

.resultsContainer {
  font-size: 1rem;
  margin-top: 2.5rem;

  .categoryHeadline {
    margin-bottom: 1.125rem;
  }

  .categoryContainer {
    margin-bottom: 2.5rem;

    .textLink {
      color: $gb_gdds_cl17;
      font-size: 1rem;
    }
  }

  .resultRow {
    align-items: center;
    border-bottom: 1px solid $gb_grey_060;
    border-top: 1px solid $gb_grey_060;
    display: flex;
    padding: 1.25rem 0;

    @include screen-md {
      padding: 5px 0;
    }

    ~ .resultRow {
      border-top: none;
    }

    .iconsContainerMobile {
      margin-top: 1.875rem;
    }

    .iconContainer {
      @include screen-md {
        padding-right: 0.313rem;
        padding-left: 0.313rem;
      }
    }

    .iconsContainer {
      display: flex;
      margin-right: 0.625rem;

      .iconWrapper {
        align-items: center;
        display: flex;
        flex-direction: row;

        > a {
          display: inline;
        }

        > span {
          display: inline;
        }

        > button {
          min-width: 0;
        }

        .icon {
          background-color: $gb_white;
        }

        .iconInverted {
          background-color: $gb_black;

          svg {
            fill: $gb_white;
          }
        }

        .iconTextMobile {
          font-size: $font-size-small;
          margin-left: 1rem;
          word-break: break-all;
        }

        &:last-child {
          margin-top: 0.625rem;
        }

        @include screen-sm {
          &:last-child {
            margin-top: 0;
          }
        }
      }
    }

    .mobileFileInfo {
      padding: 0.875rem 0.625rem 0;
    }

    .fileInfoRow {
      display: flex;
      align-items: center;
    }

    .fileIcon {
      padding-right: 0.625rem;
    }

    .mobileDate {
      color: $gb_grey_650;
      font-size: $font-size-small;
    }
  }
}

.resultCount {
  margin-top: 3.375rem;

  span {
    font-size: 1rem;
  }

  @include screen-max-md {
    margin-top: 2.5rem;
  }
}

.filterDropdown {
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.loadmoreButton {
  margin: 0 auto;
}
