@import '../../styles/base/variables';
@import '../../styles/base/mixins';

.message {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  margin-bottom: 0.5rem;
}

:global(.pageHeader--meta-nav) {
  & + .message {
    // header height so that content has enough space to top when header is fixed
    @include screen-md {
      padding-top: 9.875rem;
    }

    & ~ main {
      @include screen-md {
        padding-top: 0;
      }
    }
  }
}

.success {
  background-color: $gb_brand-utility-positive-light;
}

.alert {
  background-color: $gb_brand-utility-negative-light;
}

.warning {
  background-color: $gb_brand-utility-warning-light;
}

.info {
  background-color: $gb_grey-blue;
}

.content {
  display: inline-flex;

  i::before {
    font-size: 1.125rem;
    margin-right: 1.25rem;
  }
}
