@import 'styles/base/variables';
@import 'styles/base/mixins';

.porcelain {
  background-color: $porcelain;
}

.white {
  background-color: $gb_white;
}

.blue-grey {
  background-color: $gb_dark-grey-blue;
}

.light-grey {
  background-color: $gb_grey_060;
}

.medium-grey {
  background-color: $gb_grey_100;
}

.textWhite {
  color: $gb_white;
}

.copyText {
  margin-top: 0.625rem;

  @include screen-sm {
    margin-top: 1.5rem;
  }
}

.productTilesWrapper {
  > div {
    position: static;
  }
  margin: 4rem 0;

  &.porcelain,
  &.blue-grey,
  &.light-grey,
  &.medium-grey {
    padding: 4rem 0;
  }

  @include screen-md {
    margin: 5rem 0;

    &.porcelain,
    &.blue-grey,
    &.light-grey,
    &.medium-grey {
      padding: 5rem 0;
    }

    & .headlineCol {
      margin: 0 auto;
    }
  }

  p {
    font-size: 1rem;
    max-width: none;
  }

  .negativeMargin {
    .productTile {
      a:global(.gdds-element) {
        display: block;
        height: 100%;
        width: 100%;
        color: $gb_black;
        border: 1px solid $gb_grey_200;
        transform: none;
        padding: 0;
        box-sizing: border-box;

        &:focus {
          outline: 1px solid var(--gb-brand-primary-light);
          border: 1px solid var(--gb-brand-primary-light);
        }

        &:active {
          border: 1px solid $gb_black;
          background: none;
        }

        &:hover {
          text-decoration: none;
          color: $gb_black;

          & > span {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }
}

.centered {
  justify-content: center;
}

.negativeMargin {
  padding-top: 2rem;
  margin-top: -1.5rem;

  @include screen-md {
    padding-top: 2.5rem;
  }

  & > div {
    margin-top: 1.5rem;
  }
}

.carouselWrapper {
  li > div {
    height: 100%;
  }

  .singleTileWrapper {
    height: 100%;
  }

  // Fix swiper missing 1px in Firefox for the border right (rounding problem).
  :global(.swiper.swiper-initialized) {
    padding-right: 1px;
  }
}

.productTile {
  position: relative;
  // border top bottom otherwise it is not visible in carousel
  height: calc(100% - 2px);
  background-color: $gb_white;
  overflow: hidden;

  &:hover,
  &:active {
    .content {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .content {
    padding: 1.5rem 1.5rem 3.75rem;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    height: 100%;
  }

  .name {
    font-weight: $font_weight_bold;
    line-height: 1.5rem;
  }

  .amount {
    color: $gb_grey_650;
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    font-size: 0.875rem;
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    img {
      width: 9.5rem;

      @include screen-lg {
        width: 10rem;
      }
    }
  }

  .newLabel {
    position: absolute;
    top: 1.5rem;
    right: 0;
    padding: 0.25rem 0.5rem;
    background: var(--product-tiles-new-flag);
    color: $gb_white;
    text-transform: uppercase;
  }
}
